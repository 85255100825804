<template>
  <div
    :class="[
      'w-100 d-flex justify-content-between align-items-center px-4 px-md-5 text-white bg-brand-primary',
      small ? 'navbar-small':'navbar'
    ]"
  >
    <div>
      <a @click="$router.push(backTo)">
        <div class="d-flex justify-content-center align-items-center rounded-pill p-2 toggle-active icon-size">
          <Icon icon-name="arrowLeft"/>
        </div>
      </a>
    </div>
    
    <div class="fw-bold text-center user-select-none" style="max-width: 90%">
      <span>{{message.toUpperCase()}}<br></span>
      <small v-if="subMessage">{{subMessage.toUpperCase()}}</small>
    </div>
    
    <div>
      <router-link to="/home">
        <Icon icon-name="cancel" class="text-white icon-size p-2"/>
      </router-link>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/shared/Icon.vue';

export default {
  name: "NavBar",
  components: {
    Icon
  },
  props: {
    small: Boolean,
    backTo: {
      type: String,
      default: '',
      required: false
    },
    message: {
      type: String,
      default: '',
      required: false
    },
    subMessage: {
      type: String,
      default: '',
      required: false
    },
  }
};
</script>

<style scoped lang="scss">
@import "../../src/assets/scss/exportable.scss";

a {
  cursor: pointer;
}

.navbar {
  & {
    height: $navbar-height;
  }

  &#{-small} {
    height: $navbar-height/1.4;
  }

  &, &#{-small} {
    width: 100%;
    font-size: 16px;
    letter-spacing: 2px;
    
    .icon-size {
      font-size: 24px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .navbar {
    height: $navbar-height-mobile;
    letter-spacing: 1px;
    
    &, &#{-small} {
      font-size: 12px;
    
      .icon-size {
        font-size: 20px;
      }
    }
  }
}
</style>
