<template>
    <div ref="iconContainer">
        <div class="icon-container">
            <small v-show="!iconIsAvailable">ICON NOT AVAILABLE</small>

            <svg v-if="iconName === 'cancel'" :width="iconSize" :height="iconSize" :stroke-width="iconWeight || 2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <svg v-if="iconName === 'arrowLeft'" :width="iconSize" :height="iconSize" :stroke-width="iconWeight || 2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.5 12H6M6 12L12 6M6 12L12 18" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <svg v-if="iconName === 'navArrowUp'" :width="iconSize" :height="iconSize" :stroke-width="iconWeight || 3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 15L12 9L18 15" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <svg v-if="iconName === 'navArrowDown'" :width="iconSize" :height="iconSize" :stroke-width="iconWeight || 3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 9L12 15L18 9" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <svg v-if="iconName === 'navArrowLeft'" :width="iconSize" :height="iconSize" :stroke-width="iconWeight || 3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 6L9 12L15 18" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <svg v-if="iconName === 'navArrowRight'" :width="iconSize" :height="iconSize" :stroke-width="iconWeight || 3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 6L15 12L9 18" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <svg v-if="iconName === 'navArrowDoubleUp'" :width="iconSize" :height="iconSize" :stroke-width="iconWeight || 3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 18L12 12L18 18" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6 12L12 6L18 12" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <svg v-if="iconName === 'navArrowDoubleDown'" :width="iconSize" :height="iconSize" :stroke-width="iconWeight || 3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 6L12 12L18 6" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6 12L12 18L18 12" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <svg v-if="iconName === 'checkCircledOutline'" :width="iconSize" :height="iconSize" :stroke-width="iconWeight || 2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 12.5L10 15.5L17 8.5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <svg v-if="iconName === 'menu'" :width="iconSize" :height="iconSize" :stroke-width="iconWeight || 3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 5H21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3 12H21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3 19H21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <svg v-if="iconName === 'zoomIn'" :width="iconSize" :height="iconSize" :stroke-width="iconWeight || 1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 12H12M12 12H17M12 12V7M12 12V17" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M18 18L23 23" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M19 20L23 23" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M20 19L23 23" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4 12C4 16.4183 7.58173 20 12 20C14.2129 20 16.2161 19.1015 17.6643 17.6494C19.1077 16.2022 20 14.2053 20 12C20 7.58173 16.4183 4 12 4C7.58173 4 4 7.58173 4 12Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <svg v-if="iconName === 'zoomOut'" :width="iconSize" :height="iconSize" :stroke-width="iconWeight || 2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 12H12H17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M18 18L23 23" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M19 20L23 23" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M20 19L23 23" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4 12C4 16.4183 7.58173 20 12 20C14.2129 20 16.2161 19.1015 17.6643 17.6494C19.1077 16.2022 20 14.2053 20 12C20 7.58173 16.4183 4 12 4C7.58173 4 4 7.58173 4 12Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <svg v-if="iconName === 'star'" :width="iconSize" :height="iconSize" :stroke-width="iconWeight || 1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.58737 8.23597L11.1849 3.00376C11.5183 2.33208 12.4817 2.33208 12.8151 3.00376L15.4126 8.23597L21.2215 9.08017C21.9668 9.18848 22.2638 10.0994 21.7243 10.6219L17.5217 14.6918L18.5135 20.4414C18.6409 21.1798 17.8614 21.7428 17.1945 21.3941L12 18.678L6.80547 21.3941C6.1386 21.7428 5.35909 21.1798 5.48645 20.4414L6.47825 14.6918L2.27575 10.6219C1.73617 10.0994 2.03322 9.18848 2.77852 9.08017L8.58737 8.23597Z" fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <svg v-if="iconName === 'starOutline'" :width="iconSize" :height="iconSize" :stroke-width="iconWeight || 1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.58737 8.23597L11.1849 3.00376C11.5183 2.33208 12.4817 2.33208 12.8151 3.00376L15.4126 8.23597L21.2215 9.08017C21.9668 9.18848 22.2638 10.0994 21.7243 10.6219L17.5217 14.6918L18.5135 20.4414C18.6409 21.1798 17.8614 21.7428 17.1945 21.3941L12 18.678L6.80547 21.3941C6.1386 21.7428 5.35909 21.1798 5.48645 20.4414L6.47825 14.6918L2.27575 10.6219C1.73617 10.0994 2.03322 9.18848 2.77852 9.08017L8.58737 8.23597Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <svg v-if="iconName === 'search'" :width="iconSize" :height="iconSize" :stroke-width="iconWeight || 2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.5 15.5L19 19" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5 11C5 14.3137 7.68629 17 11 17C12.6597 17 14.1621 16.3261 15.2483 15.237C16.3308 14.1517 17 12.654 17 11C17 7.68629 14.3137 5 11 5C7.68629 5 5 7.68629 5 11Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <svg v-if="iconName === 'shareAndroid'" :width="iconSize" :height="iconSize" :stroke-width="iconWeight || 2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 22C19.6569 22 21 20.6569 21 19C21 17.3431 19.6569 16 18 16C16.3431 16 15 17.3431 15 19C15 20.6569 16.3431 22 18 22Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M18 8C19.6569 8 21 6.65685 21 5C21 3.34315 19.6569 2 18 2C16.3431 2 15 3.34315 15 5C15 6.65685 16.3431 8 18 8Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15.5 6.5L8.5 10.5" stroke="currentColor" :stroke-width="iconWeight || 1.5"/>
                <path d="M8.5 13.5L15.5 17.5" stroke="currentColor" :stroke-width="iconWeight || 1.5"/>
            </svg>

            <svg v-if="iconName === 'shareIos'" :width="iconSize" :height="iconSize" :stroke-width="iconWeight || 2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.25 7.49999H18V9.00001H19.5001V22.5H4.50002V9.00001H6.00003V7.49999H3.75001C3.33544 7.49999 3 7.83543 3 8.25V23.25C3 23.6646 3.33544 24 3.75001 24H20.25C20.6646 24 21 23.6646 21 23.25V8.25C21 7.83543 20.6646 7.49999 20.25 7.49999V7.49999Z" fill="currentColor"/>
                <path d="M11.25 2.87109V13.5H12.75V2.87109L15.9697 6.09081L17.0303 5.03027L12 0L6.96973 5.03027L8.03027 6.09081L11.25 2.87109Z" fill="currentColor"/>
            </svg>

            <svg v-if="iconName === 'copy'" :width="iconSize" :height="iconSize" :stroke-width="iconWeight || 2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.4 20H9.6C9.26863 20 9 19.7314 9 19.4V9.6C9 9.26863 9.26863 9 9.6 9H19.4C19.7314 9 20 9.26863 20 9.6V19.4C20 19.7314 19.7314 20 19.4 20Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15 9V4.6C15 4.26863 14.7314 4 14.4 4H4.6C4.26863 4 4 4.26863 4 4.6V14.4C4 14.7314 4.26863 15 4.6 15H9" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <svg v-if="iconName === 'trash'" :width="iconSize" :height="iconSize" :stroke-width="iconWeight || 2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 11V20.4C19 20.7314 18.7314 21 18.4 21H5.6C5.26863 21 5 20.7314 5 20.4V11" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10 17V11" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M14 17V11" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M21 7L16 7M3 7L8 7M8 7V3.6C8 3.26863 8.26863 3 8.6 3L15.4 3C15.7314 3 16 3.26863 16 3.6V7M8 7L16 7" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <svg v-if="iconName === 'car'" :width="iconSize" :height="iconSize" :stroke-width="iconWeight || 2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 10L16 10" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7 14L8 14" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16 14L17 14" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3 18V11.4105C3 11.1397 3.05502 10.8716 3.16171 10.6227L5.4805 5.21216C5.79566 4.47679 6.51874 4 7.31879 4H16.6812C17.4813 4 18.2043 4.47679 18.5195 5.21216L20.8383 10.6227C20.945 10.8716 21 11.1397 21 11.4105V18M3 18V20.4C3 20.7314 3.26863 21 3.6 21H6.4C6.73137 21 7 20.7314 7 20.4V18M3 18H7M21 18V20.4C21 20.7314 20.7314 21 20.4 21H17.6C17.2686 21 17 20.7314 17 20.4V18M21 18H17M7 18H17" stroke="currentColor"/>
            </svg>
            
            <svg v-if="iconName === 'garage'" :width="iconSize" :height="iconSize" :stroke-width="iconWeight || 1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.5 23V6.4L12 1L22.5 6.4V23" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5 19H12H19.5M5 14.5H19.5M5 9.5H19.5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5 9.5V23M19.5 9.5V23" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M1.5 23H22.5" stroke="currentColor"/>
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Icon',
    props: {
        iconName: {
            type: String,
            required: true
        },
        iconWeight: {
            type: Number,
            required: false
        }
    },
    data: function () {
        return {
            iconSize: 24
        };
    },
    computed: {
        iconsAvailable: function () {
            // See icons here https://iconoir.com/
            return [
                'cancel',
                'arrowLeft',
                'navArrowUp',
                'navArrowDown',
                'navArrowLeft',
                'navArrowRight',
                'navArrowDoubleUp',
                'navArrowDoubleDown',
                'checkCircledOutline',
                'menu',
                'zoomIn',
                'zoomOut',
                'star',
                'starOutline',
                'search',
                'shareAndroid',
                'shareIos',
                'copy',
                'trash',
                'car',
                'garage',
            ];
        },
        iconIsAvailable: function () {
            return this.iconsAvailable.includes(this.iconName);
        },
        requiresAlignment: function () {
            const iconNames = [
                'arrowLeft'
            ];
            return iconNames.includes(this.iconName);
        }
    },
    mounted: function () {
        const size = window.getComputedStyle(this.$refs.iconContainer, null).getPropertyValue('font-size');
        this.iconSize = size.replace(/[^\d.-]/g, '');
    }
}
</script>

<style scoped lang="scss">
    .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>