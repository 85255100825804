<template>
    <div
        v-if="message && type && colorBgByMessageType"
        class="container p-3"
        :style="$store.getters.device.isMobile ? 'max-width: 280px':'max-width: 50%'"
        @click.stop="close"
    >
        <div class="position-relative my-5 mx-3">
            <div
                :class="[
                    'alert-background-transparency',
                    'px-4 py-2 rounded',
                    colorBgByMessageType
                ]"
                style="color: transparent"
            >
                <small>
                    <span class="text-capitalize">{{type}}</span>,
                    <span class="ps-1">{{message}}</span>
                </small>
            </div>
            <div
                :class="[
                    'alert-message-conteiner',
                    'px-4 py-2 rounded',
                    colorTextByMessageType
                ]"
            >
                <small>
                    <span class="text-capitalize">{{type}}</span>,
                    <span class="ps-1">{{message}}</span>
                </small>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "Alert",
    emits: ['close'],
    props: {
        message: String,
        type: String
    },
    data: function () {
        return {
            typeBgColors: {
                'error': 'bg-danger'
            },
            typeTextColors: {
                'error': 'text-white'
            }
        };
    },
    computed: {
        colorBgByMessageType: function () {
            return this.typeBgColors[this.type] || null;
        },
        colorTextByMessageType: function () {
            return this.typeTextColors[this.type] || null;
        }
    },
    beforeMount: function () {
        setTimeout(() => {
            this.$store.commit('alert', {message: null, type: null});
        }, 5000);
    }
}
</script>

<style scoped lang="scss">
.container {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9999;
    width: auto;
    letter-spacing: 1px;

    .alert-background-transparency {
        opacity: .6;
    }

    .alert-message-conteiner {
        position: absolute;
        top: 0;
        left: 0;
    }
}
</style>
