<template>
    <div 
        :class="[
            'container-fluid',
            'mx-0 px-0',
            'h-100',
            fullPage && 'd-flex flex-fill justify-content-start align-items-start'
        ]"
        style="max-width: 1920px"
    >
        <div
            v-if="showBgImg"
            class="background-image-container w-100 h-100"
            :style="[
                `background-image: url('${bgImg}');`,
                `opacity: ${bgImgOpacity === '' ? 1:bgImgOpacity};`
            ]"
        ></div>

        <Alert v-if="alertMessage && alertType" :message="alertMessage" :type="alertType"/>
        <NavBar
            v-if="navbar"
            class="navbar"
            :back-to="backTo"
            :message="message"
            :sub-message="subMessage"
            :small="small"
        />
        <Content
            :class="[
                center && 'justify-content-center align-items-center content-height',
                vcenter && 'align-items-center content-height',
                hcenter && 'justify-content-center content-height',
                fullPage && 'w-100 justify-content-start align-items-start'
            ]"
            :style="fullPage && 'min-height: 100%'"
        >
            <slot/>
        </Content>
    </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Content from "@/components/Content.vue";
import Alert from "@/components/shared/Alert.vue";

export default {
    name: "Container",
    components:{
        NavBar,
        Content,
        Alert
    },
    props: {
        navbar: Boolean,
        small: Boolean,
        center: Boolean,
        vcenter: Boolean,
        hcenter: Boolean,
        fullPage: Boolean,
        backTo: {
            type: String,
            default: '',
            required: false
        },
        message: {
            type: String,
            default: '',
            required: false
        },
        subMessage: {
            type: String,
            default: '',
            required: false
        },
        showBgImg: Boolean
    },
    computed: {
        alertMessage: function () {
            return this.$store.getters.alert.message || null;
        },
        alertType: function () {
            return this.$store.getters.alert.type || null;
        },
        bgImg: function () {
            return this.$store.getters.clientSettings.bgImg || '';
        },
        bgImgOpacity: function () {
            return this.$store.getters.clientSettings.bgImgOpacity || '';
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../src/assets/scss/exportable.scss";

.navbar {
    z-index: 1;
}

.content-height {
    min-height: calc(100% - #{$navbar-height});
}

.background-image-container {
    position: fixed;
    top: 0;
    filter: opacity(0.6803);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: -1;
}

@media only screen and (max-width: 600px) {
    .content-height {
        min-height: calc(100% - #{$navbar-height-mobile});
    }
}
</style>