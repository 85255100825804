<template>
    <div class="row mx-0">
        <slot/>
    </div>
</template>

<script>
export default {
    name: "Content"
}
</script>
